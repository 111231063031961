import querystring from 'querystring';
import {
    keysToCamelFromSnake,
    keysToSnakeFromCamel,
} from '../utils/caseconverters';

const API_URL = process.env.WORDPRESS_API_URL;
const PAGE_URL = process.env.WORDPRESS_PAGE_URL;
const NEXT_PUBLIC_API_URL = process.env.NEXT_PUBLIC_WORDPRESS_API_URL;
const isDebug = process.env.NODE_ENV === 'development';

export async function getPage(path, params, options) {
    params = params || {};
    params = {
        // htmlPath: path,
        ...params,
    };

    const isFile = path.split('.').length > 1;
    const urlPath = isFile || path === '' ? path : `${path}/`;

    // console.log('get page', PAGE_URL, urlPath, path, params, options);

    return await getRequest(`${PAGE_URL}/${urlPath}`, params, options);
    // return await getRequest(`${API_URL}/v1/page_by_path/`, params, options);
}

export async function getPasswordProtectedPage(
    restrictionId,
    pageId,
    params,
    options
) {
    params = params || {};
    return await postRequest(
        `${NEXT_PUBLIC_API_URL}/v1/password_protected_page/${restrictionId}/${pageId}/`,
        params,
        options
    );
}

export async function getAllPages() {
    return await getRequest(`${API_URL}/v1/page_relative_urls/`);
}

export async function getPagePreview(contentType, token, params, options) {
    params = params || {};
    params = {
        contentType,
        token,
        ...params,
    };

    return await getRequest(`${API_URL}/v1/page_preview/`, params, options);
}

export async function getPublicViewData(slug, params, options) {
    return await getRequest(
        `${NEXT_PUBLIC_API_URL}/v1/external_view_data/${slug}/`,
        params,
        options
    );
}

export async function getViewData(slug, params, options) {
    return await getRequest(
        `${API_URL}/v1/external_view_data/${slug}/`,
        params,
        options
    );
}

export async function getRedirect(path, params, options) {
    params = params || {};
    params = {
        htmlPath: path,
        ...params,
    };

    return await getRequest(`${API_URL}/v1/redirect_by_path/`, params, options);
}

export async function getRequest(url, params, options) {
    params = params || {};
    params = keysToSnakeFromCamel(params);

    let headers = options?.headers || {};
    headers = {
        'Content-Type': 'application/json',
        ...headers,
    };
    const query = querystring.stringify(params);
    const queryString = query ? `?${query}` : '';

    if(isDebug) {
        console.log('fetch', `${url}${queryString}`);
    }
    const res = await fetch(`${url}${queryString}`, { headers });

    if (res.status < 200 || (res.status >= 400 && res.status !== 404)) {
        const error = new WordpressApiResponseError(res, url, params);
        error.response = res;
        throw error;
    }

    const contentType = res.headers.get('content-type');
    if((url.includes('/utbildning/') || url.includes('/webbutbildning/')) && contentType.startsWith('text/html')) {
        if(isDebug) {
            console.log('custom response utbildning', res.url);
        }

        const html = await res.text();
        return {
            json: {
                customResponse: {
                    body: html,
                    contentType,
                },
            },
            headers: res.headers,
        };
    }

    try {
        const json = await res.json();
        return {
            headers: res.headers,
            json: keysToCamelFromSnake(json),
        };
    } catch(err) {
        // If json doesn't exist, maybe it is a regular wordpress or redirection plugin redirect
        // Custom redirect for handling redirection plugin
        if(res?.redirected && res?.url) {
            if(isDebug) {
                console.log('redirect', res.redirected, res.headers, res.url);
            }

            return {
                headers: res.headers,
                json: keysToCamelFromSnake({
                    redirect: {
                        destination: res.url,
                        permanent: false,
                    },
                }),
            };
        } else {
            console.log('error api request', url, queryString, err);
            throw err;
        }
    }
}

export async function postRequest(url, params, options) {
    params = params || {};
    params = keysToSnakeFromCamel(params);

    let headers = options?.headers || {};
    headers = {
        'Content-Type': 'application/json',
        ...headers,
    };
    const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(params),
        headers,
    });

    if (res.status < 200 || res.status >= 300) {
        const error = new WordpressApiResponseError(res, url, params);
        error.response = res;
        throw error;
    }

    const json = await res.json();
    return {
        headers: res.headers,
        json: keysToCamelFromSnake(json),
    };
}

export class WordpressApiResponseError extends Error {
    constructor(res, url, params) {
        super(
            `${res.statusText}. Url: ${url}. Params: ${JSON.stringify(params)}`
        );
        this.name = 'WordpressApiResponseError';
    }
}
