import dynamic from 'next/dynamic';

export default {
    ArchitectOffice: dynamic(() => import('./ArchitectOffice')),
    ArchitectProject: dynamic(() => import('./ArchitectProject')),
    ArchitecturePrize: dynamic(() => import('./ArchitecturePrize')),
    Archive: dynamic(() => import('./Archive')),
    Blog: dynamic(() => import('./Blog')),
    BlogPost: dynamic(() => import('./BlogPost')),
    ChartPreview: dynamic(() => import('./ChartPreview')),
    Competition: dynamic(() => import('./Competition')),
    CompetitionEntry: dynamic(() => import('./CompetitionEntry')),
    Course: dynamic(() => import('./Course')),
    Event: dynamic(() => import('./Event')),
    FrontPage: dynamic(() => import('./FrontPage')),
    Landing: dynamic(() => import('./Landing')),
    LandingArkitekturgalan: dynamic(() => import('./LandingArkitekturgalan')),
    LandingMember: dynamic(() => import('./LandingMember')),
    LandingSearch: dynamic(() => import('./LandingSearch')),
    LandscapeArchitect: dynamic(() => import('./LandscapeArchitect')),
    Longread: dynamic(() => import('./Longread')),
    MemberApplications: dynamic(() => import('./MemberApplications')),
    MemberCompany: dynamic(() => import('./MemberCompany')),
    MemberLogin: dynamic(() => import('./MemberLogin')),
    MemberProfile: dynamic(() => import('./MemberProfile')),
    News: dynamic(() => import('./News')),
    NotFound: dynamic(() => import('./NotFound')),
    Page: dynamic(() => import('./Page')),
    PageArkitekturgalan: dynamic(() => import('./PageArkitekturgalan')),
    SearchPage: dynamic(() => import('./SearchPage')),
};
